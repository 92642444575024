<template>
	<div class="text-pine masonry-item">
		<div class="masonry-content">
			<router-link :to="'/' + route_news + '/' + card.slug">
				<div class="">
					<img
						v-if="card.news_image"
						class="mb-4 w-full masonry-image"
						:src="card.news_image.md"
						alt=""
					/>
				</div>
				<div class="news-info p-3">
					<div class="font-inter pb-8 mx640:text-base mx1080:text-1.5">
						<span class="mr-4">{{ card.date }}</span>
						<span>{{ card.topic }}</span>
					</div>
					<div
						class="font-tiempos_r text-24 mx640:text-6 mx1080:text-2.5 masonry-title"
					>
						{{ card.title }}
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "News",
		props: ["card"],
		data() {
			return {
				route_news: globals.navigation["news"].slug,
			};
		},
		mounted() {},
	};
</script>

<style>
	.masonry-item:hover .masonry-content {
		background-color: #193e3a;
	}
	.masonry-item:hover .news-info {
		color: #fff;
	}
	.news-info {
		transition: all 0.3s ease-in;
	}
	.masonry-content {
		transition: all 0.3s ease-in;
	}
</style>
