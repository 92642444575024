<template>
	<div class="">
		<div class="mode-center pt-170 mx640:pt-140 pb-20">
			<div class="w-full">
				<div
					class="anime-hover grid grid-cols-5 gap-24 mx900:grid-cols-1 mx900:pb-16 mx900:gap-0"
				>
					<div class="col-span-2 mx900:pb-10">
						<img
							class="w-full"
							v-if="data_hero.image"
							:src="data_hero.image.md"
						/>
						<img
							v-if="data_hero.image == null"
							class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
							src="@/assets/logo-huge.svg"
							alt=""
						/>
					</div>
					<div class="col-span-3 text-pine">
						<h1
							class="h1-text pb-8 mx640:text-9 mx900:text-6"
							v-if="data_hero.title"
						>
							{{ data_hero.title }}
						</h1>
						<div
							class="text-24 mx1280:text-2.5 mx640:text-4.5 font-tiempos_r mb-100"
							v-if="data_hero.text"
							v-html="data_hero.text"
						></div>
						<div class="">
							<div class="text-crab font-inter pb-6 text-22">
								{{ hdr_newsletter }}
							</div>
							<div class="form w-full inline-block text-xl">
								<form
									action="https://group.us16.list-manage.com/subscribe/post?u=22a9f69c2dcb7792e81e1c639&amp;id=8df76ccc64"
									method="post"
									id="mc-embedded-subscribe-form"
									name="mc-embedded-subscribe-form"
									target="_blank"
									novalidate
									class="flex justify-between border-b border-crab"
								>
									<input
										type="email"
										value=""
										name="EMAIL"
										id="mce-EMAIL"
										class="py-4 text-crab font-inter focus:outline-none w-full placeholder-crab_place focus:placeholder-opacity-0"
										:placeholder="email_newsletter"
									/>
									<button
										type="submit"
										name="subscribe"
										id="mc-embedded-subscribe"
										class="text-crab nav-btn mr-0 font-inter focus:outline-none"
									>
										{{ btn_newsletter }}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="news bg-shea">
			<div class="mode-center pt-10 pb-100">
				<div class="flex justify-end text-pine pb-16 relative mx640:pb-8 z-10">
					<div v-click-outside="hideDropDown">
						<button
							type="button"
							@click="toggleDropDown"
							class="inline-flex nav-btn mr-65 items-center focus:outline-none mx640:text-4.5 mx640:mr-0"
							id="options-menu"
							aria-expanded="true"
							aria-haspopup="true"
						>
							{{ selectedTopic || btn_news_show_all }}
							<svg
								class="-mr-1 ml-2 h-5 w-5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					</div>
					<div
						v-if="isDropDownOpen"
						class="origin-top-right absolute right-0 mt-10 w-56 rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="options-menu"
					>
						<div class="py-1 font-inter" role="none">
							<p
								v-for="topic in newsPageData.topics"
								:key="topic"
								@click="handleSelectedTopic(topic)"
								class="block px-4 py-2  text-crab hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
								role="menuitem"
							>
								{{ topic }}
							</p>
						</div>
					</div>
				</div>
				<div class="masonry-wrapper">
					<div class="masonry">
						<News
							v-for="card in filterdNewsByTopic.slice(0, currentAmount)"
							:key="card.id"
							:card="card"
						/>
					</div>
				</div>
				<div class="flex justify-center">
					<img
						v-if="!isListComplete"
						@click="showMoreNews"
						class="cursor-pointer"
						src="@/assets/news/plus.svg"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import data from "@/data.json";
	import News from "@/components/ui/News";
	import ClickOutside from "vue-click-outside";
	import { globals } from "@/modules/globals";

	export default {
		name: "NewsPage",
		data() {
			return {
				hdr_newsletter: globals.microcopy.hdr_newsletter[globals.language],
				email_newsletter: globals.microcopy.email_newsletter[globals.language],
				btn_newsletter: globals.microcopy.btn_newsletter[globals.language],
				newsPageData: {
					items: [],
					topics: [],
				},
				selectedTopic: globals.microcopy.btn_news_show_all[globals.language],
				tempData: data,
				isDropDownOpen: false,
				currentAmount: 6,
				topics: [],
				btn_news_show_all:
					globals.microcopy.btn_news_show_all[globals.language],
				data_hero: {},
			};
		},
		mounted() {
			if (this.$route.query.topic) {
				const newsSection = document.querySelector(".news");
				const top = newsSection.offsetTop;
				window.scrollTo(0, top);
				this.selectedTopic =
					globals.language === "nl" ? "Vacatures" : "Vacancies";
			}
			var masonryEvents = ["load", "resize"];
			masonryEvents.forEach((event) => {
				window.addEventListener(event, this.resizeAllMasonryItems);
			});

			this.waitForImages();
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 2;

			let newsData = null;
			let json_file_news =
				"/json/news/news_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_news)
				.then((response) => response.json())
				.then(function(data) {
					newsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_news_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(newsData, sectionsData));
				}
			}
		},
		computed: {
			filterdNewsByTopic: function() {
				if (this.selectedTopic === this.btn_news_show_all) {
					return this.newsPageData.items;
				}
				return this.newsPageData.items.filter(
					(news) => news.topic === this.selectedTopic
				);
			},
			isListComplete: function() {
				return this.filterdNewsByTopic.length >= this.currentAmount
					? false
					: true;
			},
		},
		updated() {
			this.waitForImages();
		},
		directives: {
			ClickOutside,
		},
		components: {
			News,
		},
		methods: {
			setData(newsData, sectionsData) {
				var topics_array = [this.btn_news_show_all];
				topics_array = topics_array.concat(newsData.topics);
				this.newsPageData = {
					items: newsData.items,
					topics: topics_array,
				};

				this.data_hero = sectionsData["news-intro"];
			},
			toggleDropDown() {
				this.isDropDownOpen = !this.isDropDownOpen;
			},
			hideDropDown() {
				this.isDropDownOpen = false;
			},
			showMoreNews() {
				this.currentAmount = this.currentAmount + 6;
			},
			handleSelectedTopic(event) {
				this.selectedTopic = event;
			},
			resizeAllMasonryItems() {
				var allItems = document.querySelectorAll(".masonry-item");

				if (allItems) {
					allItems.forEach((item) => this.resizeMasonryItem(item));
				}
			},
			resizeMasonryItem(item) {
				/* Get the grid object, its row-gap, and the size of its implicit rows */
				var grid = document.getElementsByClassName("masonry")[0];
				if (grid) {
					var rowGap = parseInt(
							window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
						),
						rowHeight = parseInt(
							window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
						);
					var rowSpan = Math.ceil(
						(item.querySelector(".masonry-content").getBoundingClientRect()
							.height +
							rowGap) /
							(rowHeight + rowGap)
					);
					/* Set the spanning as calculated above (S) */
					item.style.gridRowEnd = "span " + rowSpan;
				}
			},
			waitForImages() {
				var imagesLoaded = require("imagesloaded");
				var allItems = document.querySelectorAll(".masonry-item");
				if (allItems) {
					for (var i = 0; i < allItems.length; i++) {
						imagesLoaded(allItems[i], (instance) => {
							var item = instance.elements[0];
							this.resizeMasonryItem(item);
						});
					}
				}
			},
		},
	};
</script>

<style>
	.masonry-wrapper {
		padding: 1.5em;
		max-width: 1290px;
		margin-right: auto;
		margin-left: auto;
	}
	.masonry {
		display: grid;
		grid-template-columns: repeat(3, minmax(100px, 1fr));
		grid-gap: 20px;
		grid-auto-rows: 0;
	}
	@media only screen and (max-width: 900px) {
		.masonry {
			grid-template-columns: repeat(2, minmax(100px, 1fr));
		}
	}
	@media only screen and (max-width: 640px) {
		.masonry {
			grid-template-columns: repeat(1, minmax(100px, 1fr));
		}
	}
</style>
