<template>
	<div class="">
		<div v-if="show === 'yes'">
			<div class="news-header">
				<div class="anime-hover mode-center pt-170 pb-20">
					<router-link
						class="text-xl font-inter text-pine py-2 mx640:text-3.5"
						to="/news"
						@click.native.prevent="navigate_back_to_news"
					>
						{{ btn_news_back }}
					</router-link>
					<div class="w-93% ml-20 mx900:ml-0 mx640:w-full">
						<div
							class="anime-hover grid grid-cols-8 pt-100 text-pine pb-10 mx640:grid-cols-1"
						>
							<div v-if="singleNews.news_image" class="col-span-3 mx640:mb-6">
								<img class="w-full" :src="singleNews.news_image.md" alt="" />
							</div>
							<div
								class="flex flex-col justify-between col-span-4 col-start-5 mx640:col-start-1"
							>
								<div class="font-inter pb-8 mx640:text-base mx1080:text-1.5">
									<span class="mr-4">{{ singleNews.date }}</span>
									<span>{{ singleNews.topic }}</span>
								</div>
								<h1
									class="h1-text mx1280:text-4 mx640:text-6 mx900:leading-10 mx1080:leading-48 leading-70 mb-24 mx900:mb-12"
								>
									{{ singleNews.title }}
								</h1>
								<p
									class="text-30 font-tiempos_r mx1280:text-2 mx900:text-3 mx900:leading-7 mx640:text-4 leading-48"
								>
									{{ singleNews.summary }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="">
				<div
					class="news-block anime-hover"
					v-for="(block, index) in singleNews.blocks"
					:key="index"
					:style="{ backgroundColor: block.color }"
				>
					<div v-if="block.color != '#FF7E4B'">
						<div class="mode-center pt-120 text-pine pb-32">
							<div class="w-90% grid grid-cols-9 mx900:w-full mx640:block">
								<div class="news-author col-span-2 mx640:mb-12 font-inter">
									<p class="pb-4">{{ txt_written_by }}</p>
									<div class="flex items-center mx900:block">
										<div v-if="singleNews.author.image" class="mr-4 mx900:mb-6">
											<img
												class="rounded-full w-16 h-16 object-cover"
												:src="singleNews.author.image.sm"
												alt=""
											/>
										</div>
										<div class="">
											<p>{{ singleNews.author.first_name }}</p>
											<p>{{ singleNews.author.last_name }}</p>
										</div>
									</div>
								</div>
								<div
									v-html="block.content"
									class="rich-text col-span-6 col-start-4 font-tiempos_r mx900:col-start-3 mx900:col-span-7"
								></div>
							</div>
						</div>
					</div>

					<div v-if="block.color == '#FF7E4B'">
						<div class="mode-center pb-12 pt-16">
							<div
								class="anime-hover w-90% grid grid-cols-9 mx900:w-full mx640:block"
							>
								<div
									class="col-span-6 col-start-4 text-20 font-inter leading-8 mx1080:text-2.5 mx1080:leading-9 mx640:text-4 mx900:col-start-3 mx900:col-span-7"
									v-html="block.content"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page_404 v-if="show === '404'" />
	</div>
</template>

<script>
	import data from "@/data.json";
	import { globals } from "@/modules/globals";
	import page_404 from "@/components/page_404.vue";

	export default {
		name: "NewsSingle",
		data() {
			return {
				tempData: data,
				slug: this.$route.params.slug,
				news_image: "",
				author_image: "",
				singleNews: {},
				show: null,
				btn_news_back: globals.microcopy.btn_news_back[globals.language],
				txt_written_by: globals.microcopy.txt_written_by[globals.language],
			};
		},
		components: {
			page_404,
		},
		beforeRouteEnter(to, from, next) {
			let slug = to.params.slug;
			let json_file =
				"/json/news/news_" +
				globals.language +
				"__" +
				slug +
				".json?v=" +
				globals.content_version;
			fetch(json_file)
				.then((response) => response.json())
				.then(function(data) {
					globals.news_item_cur = data
					next((vm) => vm.setData(data));
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
					next((vm) => vm.notFound());
				});
		},
		methods: {
			setData(data) {
				this.show = "yes";
				this.singleNews = data;
				//console.log(data)
			},
			notFound() {
				this.show = "404";
			},
			navigate_back_to_news() {
				if (window.history.length > 3) {
					window.history.back();
				} else {
					this.$router.push({ name: "news" });
				}
			},
		},
	};
</script>

<style>
	.news-header {
		background: url("../assets/sun-2.svg") no-repeat;
		background-size: 2100px 2300px;
		background-position: left;
		background-position-y: -320px;
	}

	@media only screen and (max-width: 640px) {
		.news-header {
			background-size: unset;
			background-position-y: 0px;
		}
	}
	@media only screen and (min-width: 1600px) {
		.news-header {
			background-size: cover;
			background-position-y: 0px;
		}
	}
</style>
